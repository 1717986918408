import React, { useEffect, useState } from "react";

import "./Newsletter.css";
import { SubHeading } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "@formspree/react";
import "react-toastify/dist/ReactToastify.css";
const Newsletter = () => {
  const [state, newLetterSubmit,reset] = useForm("mnnadwzv");

  const [message, setMessage] = useState("");
  const [phone,setPhone]=useState("");

  const handlerSubmit = (e) => {
    e.preventDefault()
    if (message === "" || phone.length < 10) {
      toast("Please add details before subscribe");
    } else newLetterSubmit(e);
  };

  useEffect(()=>{
    if(state.succeeded){
      setMessage("")
      setPhone("")
      toast("Added Successfully");
      reset()
    }
    //eslint-disable-next-line
  },[state])

  return (
    <>
      <div className="app__newsletter">
        <div className="app__newsletter-heading">
          <SubHeading title="" />
          <div className="app__specialMenu-title">
              <div style={{marginBottom:'1rem'}}>
              <p className="p__cormorant">
              Add Details if you want your own website
          </p>
        </div>
      </div>
          {/* <p className="p__opensans">And Never Miss Latest Updates!</p> */}
        </div>
        <form className="app__newsletter-input flex_center" onSubmit={handlerSubmit} method="POST">
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter your e-mail address"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <input
            id="phone"
            type="tel"
            name="phone"
            placeholder="Enter your phone no"
            value={phone}
            maxLength={10}
            onChange={(e) => setPhone(e.target.value)}
          />
          <button className="custom__button" type="submit">
            Add Details
          </button>

          {/* {window.innerWidth <} */}
          <ToastContainer className="alert-box" />
        </form>
      </div>
    </>
  );
};

export default Newsletter;
