import React from "react";
// import { useState } from "react";
// import { GiHamburgerMenu } from "react-icons/gi";
// import { MdOutlineRestaurantMenu } from "react-icons/md";

// import images from "../../constants/images";
// import SignUp from "../../container/SignUp/SignUp";
import orderItLogo from "../../assets/orderItLogo.png"

import "./Navbar.css";

const Navbar = () => {
  // const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <h1 className='app__footer-headtext' style={{fontSize: '2.5rem', marginTop:"1rem"}}>Sip & Slice</h1>
      </div>
      {/* <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="/">Home</a>
        </li>
        <li className="p__opensans">
          <a href="/about">About</a>
        </li>
        <li className="p__opensans">
          <a href="/menu">Menu</a>
        </li>
        <li className="p__opensans">
          <a href="/awards">Awards</a>
        </li>
        <li className="p__opensans">
          <a href="/contact">Contact</a>
        </li>
      </ul> */}
      <div className="app__navbar-login">
        <img src={orderItLogo} alt="company_logo" className="orderItLogo" width={"60px"} height={"55px"} style={{borderRadius:"20px"}}/>
        {/* <a href="/login" className="p__opensans">
          Log In / Register
        </a>
        <div />
        <a href="/" className="p__opensans">
          Book Table
        </a> */}
      </div>
      {/* <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          className="GiHam"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen-links">
              <li className="p__opensans">
                <a href="/">Home</a>
              </li>
              <li className="p__opensans">
                <a href="/about">About</a>
              </li>
              <li className="p__opensans">
                <a href="/menu">Menu</a>
              </li>
              <li className="p__opensans">
                <a href="/awards">Awards</a>
              </li>
              <li className="p__opensans">
                <a href="/contact">Contact</a>
              </li>
              <li className="p__opensans">
                <a href="/login">Login/Register</a>
              </li>
            </ul>
          </div>
        )}
      </div> */}
    </nav>
  );
};

export default Navbar;
