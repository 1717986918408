import React from "react";

import {
  AboutUs,
  Chef,
  FindUs,
  Footer,
  Gallery,
  // Header,
  Intro,
  Laurels,
  // SpecialMenu,
  // FullMenu,
  Home,
  // SignUp,
} from "./container";
import { Navbar } from "./components";
import "./App.css";
import SignUp from "./container/SignUp/SignUp";
import { FullMenu } from "./container";
// import createHistory from "history/createBrowserHistory";
// import { configureStore } from "@reduxjs/toolkit";
// import Provider from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  // const history = createHistory({
  //   basename: process.env.PUBLIC_URL,
  // });

  // const store = configureStore({ history });
  return (
    <>
      <Navbar />
      {/* <Provider store={store}> */}
      <Router>
        <Routes>
          <Route exact path="/login" element={<SignUp />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/menu" element={<FullMenu />} />
          <Route exact path="/chef" element={<Chef />} />
          <Route exact path="/intro" element={<Intro />} />
          <Route exact path="/awards" element={<Laurels />} />
          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/contact" element={<FindUs />} />
          <Route exact path="/footer" element={<Footer />} />
          {/* <Route exact path="/login" element={<SignUp />} /> */}
        </Routes>
      </Router>
      {/* </Provider> */}
      {/* <SignUp />*/}
    </>
  );
};

export default App;
