import React from 'react';
// import {FiFacebook,FiTwitter,FiInstagram} from 'react-icons/fi';
import { FooterOverlay,Newsletter } from '../../components';
// import {images} from '../../constants';
import './Footer.css';

const Footer = () => (
  <div className='app__footer section__padding'>
    <FooterOverlay/>
    <Newsletter/>

    <div className='app__footer-links'>
      <div className='app__footer-links_contact'>
        <h1 className='app__footer-headtext'>Contact Us</h1>
        <p className='p__opensans'>Plot No 181B, Street 3, Near Maruti Gate, sector 18, Gurugram</p>
        <p className='p__opensans'>+91-9064994884</p>
        <p className='p__opensans'>+91-6395113242</p>

      </div>
      <div className='app__footer-links_logo'>
        {/* <img src={images.gericht} alt="footer-logo"/> */}
        <h1 className='app__footer-headtext'>Sip & Slice</h1>
        {/* <img src={images.spoon} alt="spoon" className="spoon__img" style={{marginTop:15}}/> */}
        <p className='p__opensans'>"The best way to find yourself is to lose yourself in the service of others."</p>
        {/* <div className='app__footer-links_icons'>
          <FiFacebook/>
          <FiTwitter/>
          <FiInstagram/>
        </div> */}
      </div>
      <div className='app__footer-links_work'>
        <h1 className='app__footer-headtext'>Working Hours</h1>
        <p className='p__opensans'>Monday-Sunday</p>
        <p className='p__opensans'>10:00 am-10:00 pm</p>
      </div>
    </div>
    <div className='footer__copyright'>
      <p className='p__opensans'>2024 OrderIt. All Rights reserved</p>
    </div>
  </div>
);

export default Footer;
