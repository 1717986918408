import React, { useEffect, useRef } from "react";
import { SubHeading } from "../../components";
import "./SignUp.css";
import { ToastContainer, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MuiAlert from "@material-ui/lab/Alert";
// import { BsFillPlayFill, BsPauseFill } from "react-icons/bs";
import login1 from "../../assets/login3.mp4";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const SignUp = () => {
  // const notify = () => toast("Account Created Successfully");
  const vidRef = useRef(null);
  useEffect(() => {
    vidRef.current.play();
  });

  const handleClick = () => {
    <Alert severity="success">Email Added SuccessFully</Alert>;
  };
  return (
    <>
      <div className="app__login">
        <div className="app__login-page" id="login">
          <div className="app__login-design">
            <SubHeading title="Register Here" />
            <p className="p__opensans login-para">
              Sign up with one of the options
            </p>
            <div>
              <a href="/" className="custom__link">
                Google
              </a>
              <a href="/" className="custom__link">
                Facebook
              </a>
            </div>

            <div className="app__login-input">
              <p className="app__login-text">Name</p>
              <input type="text" placeholder="Enter your Name" />

              <p className="app__login-text">Mobile No.</p>
              <input type="number" placeholder="Enter your Phone" />
              <p className="app__login-text">Email</p>
              <input type="email" placeholder="exapmle: kapish@gmail.com" />

              <p className="app__login-text">Password</p>
              <input type="password" placeholder="Pick a strong password" />
            </div>

            <button
              type="button"
              className="custom__button login_button"
              onClick={() => handleClick}
            >
              Create new Account
            </button>
            <ToastContainer />
            <p className="p__opensans text">
              Already have an account?
              <a href="/" className="p__opensans login-mini">
                Log In
              </a>
            </p>
          </div>
          <div className="app__login-video flex__center">
            <video ref={vidRef} loop controls={false} muted>
              <source src={login1} />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
