import React from "react";
// import { SubHeading, MenuItem } from "../../components";
import "./FulllMenu.css";
// import { data } from "../../constants";
import { images } from "../../constants";
import demoMenu from '../../assets/demoMenu.png';
// import CommonMenu from "./CommonMenu";
const FullMenu = () => {
  return (
    <div className="orderItMenu">
      <div className="app__specialMenu-title">
        <div style={{marginBottom:'1rem'}}>
          <p>
          Menu that fits to your pallete
          </p>
          <img src={images.spoon} alt="spoon" className='spoon__img'/>
        </div>
      </div>
      {/* <CommonMenu
        subheading="Menu that fits to your pallete"
        heading="Chinese Food"
        name="Burger"
        name1="Pizza"
        aname="chinese"
        aname1="chinese"
        img="https://images.unsplash.com/photo-1587015566802-5dc157c901cf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1886&q=80"
        style={{ objectFit: "contain", filter: "invert(4%)" }}
      />
      <CommonMenu
        heading="Liquors"
        name="Wines & Beers"
        name1="Cocktails"
        aname="wines"
        aname1="cocktails"
        img="https://images.pexels.com/photos/588575/pexels-photo-588575.jpeg?auto=compress&cs=tinysrgb&w=600"
        style={{ objectFit: "contain", filter: "invert(4.5%)" }}
      />
      <CommonMenu
        heading="Indian Food"
        name="Roti & Parantha"
        name1="Awesome Sabji's"
        aname="wines"
        aname1="cocktails"
        img="https://images.pexels.com/photos/6089654/pexels-photo-6089654.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        style={{ objectFit: "contain", filter: "brightness(70%)" }}
      /> */}
      <img src={demoMenu} className="demoImg" alt='yo' />
    </div>
  );
};

export default FullMenu;
