import React from "react";

import {
  // AboutUs,
  // Chef,
  FindUs,
  Footer,
  FullMenu,
  // Gallery,
  // Header,
  Intro,
  // Laurels,
  // SpecialMenu,
  // SignUp,
} from ".";

const Home = () => {
  return (
    <>
      {/* <Header /> */}
      {/* <AboutUs /> */}
      {/* <SpecialMenu /> */}
      <FullMenu />
      {/* <Chef /> */}
      <Intro />
      {/* <Laurels /> */}
      {/* <Gallery /> */}
      <FindUs />
      <Footer />
    </>
  );
};

export default Home;
