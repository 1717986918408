import bg from "../assets/bg.webg";
import chef from "../assets/chef.webg";
import G from "../assets/G.webg";
import gallery01 from "../assets/gallery01.webg";
import gallery02 from "../assets/gallery02.webg";
import gallery03 from "../assets/gallery03.webg";
import gallery04 from "../assets/gallery04.webg";
import knife from "../assets/knife.webg";
import logo from "../assets/logo.webg";
import menu from "../assets/menu.webg";
import overlaybg from "../assets/overlaybg.webg";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/welcome.webg";
import findus from "../assets/findus.webg";
import laurels from "../assets/laurels.webg";
import award01 from "../assets/award01.webg";
import award02 from "../assets/award02.webg";
import award03 from "../assets/award03.webg";
import award05 from "../assets/award05.webg";
import sign from "../assets/sign.webg";
import quote from "../assets/quote.webg";
import gericht from "../assets/gericht.webg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  bg,
  chef,
  G,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  knife,
  logo,
  menu,
  overlaybg,
  spoon,
  welcome,
  findus,
  laurels,
  award01,
  award02,
  award03,
  award05,
  sign,
  quote,
  gericht,
};
