import images from "./images";

const wines = [
  {
    title: "Chapel Hill Shiraz",
    price: "₹250",
    tags: "AU | Bottle",
  },
  {
    title: "Catena Malbee",
    price: "₹325",
    tags: "AU | Bottle",
  },
  {
    title: "La Vieillw Rose",
    price: "₹440",
    tags: "FR | 750 ml",
  },
  {
    title: "Rhino Pale Ale",
    price: "₹150",
    tags: "CA | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
  {
    title: "Irish Guinness",
    price: "₹250",
    tags: "IE | 750 ml",
  },
];
const chinese = [
  {
    title: "Egg Burger",
    price: "₹70",
    tags: "",
  },
  {
    title: "Pizza Burger",
    price: "₹90",
    tags: "",
  },
  {
    title: "Veg Burger",
    price: "₹65",
    tags: "",
  },
  {
    title: "Chowmein",
    price: "₹80",
    tags: "",
  },
  {
    title: "Hakka Noodles",
    price: "₹110",
    tags: "",
  },
  {
    title: "Veg Noodles",
    price: "₹79",
    tags: "",
  },
  {
    title: "Momos",
    price: "₹75",
    tags: "",
  },
  {
    title: "Fried Momos",
    price: "₹96",
    tags: "",
  },
  {
    title: "Cocktail Momos",
    price: "₹105",
    tags: "",
  },
  {
    title: "Fried Momos",
    price: "₹96",
    tags: "",
  },
  {
    title: "Cocktail Momos",
    price: "₹105",
    tags: "",
  },
  {
    title: "Veg Noodles",
    price: "₹79",
    tags: "",
  },
  {
    title: "Momos",
    price: "₹75",
    tags: "",
  },
  {
    title: "Cocktail Momos",
    price: "₹105",
    tags: "",
  },
  {
    title: "Fried Momos",
    price: "₹96",
    tags: "",
  },
  {
    title: "Veg Noodles",
    price: "₹79",
    tags: "",
  },
  {
    title: "Momos",
    price: "₹75",
    tags: "",
  },
  {
    title: "Fried Momos",
    price: "₹96",
    tags: "",
  },
  {
    title: "Momos",
    price: "₹75",
    tags: "",
  },
  {
    title: "Fried Momos",
    price: "₹96",
    tags: "",
  },
  {
    title: "Cocktail Momos",
    price: "₹105",
    tags: "",
  },
  {
    title: "Fried Momos",
    price: "₹96",
    tags: "",
  },
  {
    title: "Veg Noodles",
    price: "₹79",
    tags: "",
  },
];
const cocktails = [
  {
    title: "Aperol Sprtiz",
    price: "₹200",
    tags: "Aperol | Villa Marchesi prosecco",
  },
  {
    title: "Dark 'N' Stormy",
    price: "₹160",
    tags: "Dark rum | Ginger beer | Slice of lime",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Aperol Sprtiz",
    price: "₹200",
    tags: "Aperol | Villa Marchesi prosecco",
  },
  {
    title: "Dark 'N' Stormy",
    price: "₹160",
    tags: "Dark rum | Ginger beer | Slice of lime",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Aperol Sprtiz",
    price: "₹200",
    tags: "Aperol | Villa Marchesi prosecco",
  },
  {
    title: "Dark 'N' Stormy",
    price: "₹160",
    tags: "Dark rum | Ginger beer | Slice of lime",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Aperol Sprtiz",
    price: "₹200",
    tags: "Aperol | Villa Marchesi prosecco",
  },
  {
    title: "Dark 'N' Stormy",
    price: "₹160",
    tags: "Dark rum | Ginger beer | Slice of lime",
  },
  {
    title: "Daiquiri",
    price: "₹290",
    tags: "Rum | Citrus juice | Sugar",
  },
  {
    title: "Aperol Sprtiz",
    price: "₹200",
    tags: "Aperol | Villa Marchesi prosecco",
  },
  {
    title: "Dark 'N' Stormy",
    price: "₹160",
    tags: "Dark rum | Ginger beer | Slice of lime",
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: "Bib Gourmond",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award01,
    title: "Rising Star",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award05,
    title: "AA Hospitality",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
  {
    imgUrl: images.award03,
    title: "Outstanding Chef",
    subtitle: "Lorem ipsum dolor sit amet, consectetur.",
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default { wines, cocktails, chinese, awards };
